@import "../../styles/Colors.scss";
.ant-slider .ant-slider-track {
  background-color: var(--background-color) !important;
}
.filters-drawer {
  .ant-drawer-header {
    // display: none !important;
    // display: revert;
    // padding: 0px;
    // border: none;
    padding: 16px 10px !important;
    padding-right: 5px;
    border-bottom: 3px solid #edebef;
    padding: 12px;
    .ant-drawer-header-title {
      // background-color: #ffffff;
      // border-radius: 50%;
      // position: absolute;
      // right: -30px;
      // top: 10px;
      // button {
      //   margin: 0px;
      //   padding: 2px 4px;
      //   margin-bottom: 2px;
      //   svg {
      //     font-size: 12px;
      //   }
      // }
      .ant-drawer-title {
        font-size: 25px !important;
        font-family: "ZemestroStd-Medium" !important;
        font-weight: 100 !important;
        color: $black;
      }

      button.ant-drawer-close {
        position: absolute;
        right: -50px;
        background-color: $surface;
        border-radius: 50%;
        svg {
          margin: 5px 0px;
          font-size: 14px;
        }
      }
    }
  }

  .ant-drawer-body {
    color: #000000;
    padding: 0px !important;

    .ant-collapse {
      background-color: #ffffff;
      // svg {
      //   display: none;
      // }

      .ant-collapse-item {
        padding: 0px 12px;
      }

      .panel-search-icon {
        position: absolute;
        right: 20px;
        margin: -35px 0px 0px 0px;
        color: $filterOptionText;
        font-size: 18px;
      }

      .ant-input-group-wrapper {
        margin-bottom: 10px;
        .ant-input-affix-wrapper {
          height: 28px;
          border-radius: 0px;
        }
      }

      .ant-collapse-header {
        width: 250px;
        padding: 10px 0px;
        padding-left: 5px;
        .ant-collapse-header-text {
          font-size: 16px;
          font-family: "ZemestroStd-Medium";
          color: $black;
        }
      }

      .ant-collapse-content {
        overflow-y: scroll;
        max-height: 200px;
        border-top: 1px solid $filterBorder;

        .ant-collapse-content-box {
          // padding: 0px 10px;
          padding: 0px;
          // overflow-y: scroll;
          // max-height: 200px;
          .ant-row {
            .ant-col {
              border-bottom: 1px solid $filterBorder;
              padding: 4px 0px;
              .ant-checkbox-wrapper {
                // font-size: 14px !important;
                color: $filterOptionText;
                font-size: 14px;
                // align-items: center;
                padding-left: 5px;

                .ant-checkbox {
                  label {
                    padding-right: 10px;
                  }
                }

                .color-option-container {
                  height: 16px;
                  width: 16px;
                  margin-right: 6px;
                  border-radius: 50%;
                }
                .gender-option-container {
                  margin-right: 6px;
                }
                span {
                  display: flex;
                  align-items: center;
                }
              }
            }
          }

          .show-more-button {
            color: $primaryThree;
            font-family: "ZemestroStd-Medium" !important;
            font-weight: 100 !important;
            font-size: 12px;
            text-align: center;
            padding: 10px 0px;
          }
        }
      }

      .ant-input-search {
        button {
          display: none;
        }
      }

      .price-filter {
        .ant-collapse-content-box {
          padding: 10px !important;
        }
      }
    }

    // price filter

    .ant-slider {
      margin-top: 18px;
      .ant-slider-rail {
        height: 2px;
        background-color: #edebef;
      }
      .ant-slider-track {
        height: 2px;
        // background-color: $primaryThree;
        background-color: var(--background-color) !important;
      }
      // .ant-slider-handle {
      //   border: 1px solid #000000;
      //   width: 14px;
      //   height: 14px;
      //   margin-top: -5px;
      //   background-color: #ffffff;
      // }
    }

    .ant-slider .ant-slider-handle::after {
      content: "";
      position: absolute;
      inset-block-start: 0;
      inset-inline-start: 0;
      width: 10px;
      height: 10px;
      background-color: var(--background-color) !important;
      box-shadow: 0 0 0 2px var(--background-color) !important;
      border-radius: 50%;
      cursor: pointer;
      transition:
        inset-inline-start 0.2s,
        inset-block-start 0.2s,
        width 0.2s,
        height 0.2s,
        box-shadow 0.2s;
    }

    .price-input-field {
      background-color: $background;
    }
    .input-field-split {
      background-color: $surface;
    }
  }

  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

.ant-tooltip {
  top: 30px;
  .ant-tooltip-inner {
    background-color: $primaryThree;
    font-size: 10px;
    min-height: 16px;
    padding: 2px 4px;
    text-align: center;
  }

  .ant-tooltip-arrow:before {
    background-color: $primaryThree;
  }
}

// for both desktop and mobile

.price-filter {
  border: 1px solid $surfaceBorder;
  padding: 2px;
  .ant-input {
    width: 80px;
    background: $background;
    border-radius: 0px;
    font-size: 12px;
    text-align: center;
  }
  // .ant-input-disabled {
  //   width: 10px;
  //   background: $surface;
  //   border: 0px;
  // }

  .input-field-split {
    padding: 0px 6px;
    font-size: 10px;
  }
  button {
    background: $surface;
    border: 0px;
    // color: #d62215;
    color: var(--background-color) !important;
    padding: 0px 18px;
    cursor: pointer;
    width: max-content;
    height: 35px;
    // border: 1px solid #d62215;
    border: 1px solid var(--background-color) !important;
    width: 50%;
    border-radius: 6px;
  }
}

.ant-select {
  .ant-select-arrow {
    color: #000000 !important;

    svg {
      font-size: 12px;
      color: #000000 !important;
    }
  }
}

// Mz css
.apply-reset-price .reset {
  margin-right: 10px;
  color: var(--background-color) !important;
}
.filters-drawer .ant-drawer-body .ant-collapse .price-filter .ant-collapse-header {
  width: 98%;
}
// .apply-reset-price .apply {
//   background-color: var(--background-color) !important;
//   // background-color: #d62215;
//   color: #fff;
//   border: 1px solid var(--background-color) !important;
// }
.filters-drawer .ant-drawer-body .price-input-field {
  border-radius: 5px !important;
}
.price-filter {
  border: unset;
}
.price-header {
  display: flex;
  justify-content: space-between;
}

// Mz Css
.reset-all-filters {
  background: $surface;
  border: 0px;
  color: $primaryThree;
  padding: 0px 18px;
  cursor: pointer;
  width: max-content;
  height: 30px;
  border: 1px solid #ff3f6c;
}
.desktop-filter-header {
  display: flex;
  align-items: center;
}

// Alfa Mz Css
.filter-drawer .panel-search-icon {
  display: none;
}
body .filter-drawer .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-left: 0;
  padding-right: 0;
}
body .filter-drawer .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-left: 0;
  padding-right: 0;
}
body .filter-drawer .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text,
.price-header span {
  font-size: 20px;
  font-weight: 900;
  font-family: "ZemestroStd-Medium";
}
body .show-more-button {
  color: #d62215;
  margin-top: 10px;
  cursor: pointer;
}
.collection_page_wrapper .filter-drawer .ant-collapse-content-box .ant-row {
  margin-bottom: 10px;
}
.filter-drawer .ant-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  border: 2px solid #bdbdbd;
  margin-right: 5px;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner {
  border-color: #d62215 !important;
  // border-color: var(--background-color) !important;
}
.filter-drawer .ant-slider .ant-slider-handle::after {
  box-shadow: 0 0 0 2px var(--background-color) !important;
  // box-shadow: 0 0 0 2px #d62215 !important;
}
.filter-drawer .ant-slider .ant-slider-handle:hover::after {
  // box-shadow: 0 0 0 4px var(--background-color) !important;
  box-shadow: 0 0 0 4px #d62215 !important;
}
.filter-drawer .price-filter input {
  height: 40px;
  background: #fff;
  border: 1px solid #dee2e7;
  border-radius: 6px;
}
.price-filter.ant-input-group label {
  font-size: 16px;
  font-weight: 600;
  font-family: "ZemestroStd-Regular";
  margin: 7px;
}

.apply-reset-price {
  display: flex;
  margin-top: 15px;
}
.filter-drawer .ant-slider .ant-slider-rail {
  background: rgba(214, 35, 20, 0.25);
}

.filters-container .ant-tag {
  font-size: 16px;
  padding: 10px;
  color: rgba(80, 80, 80, 1);
  border-color: var(--background-color) !important;
}
.filters-container .ant-tag .anticon-close svg {
  width: 14px;
  height: 14px;
  margin-left: 10px;
  color: #8b96a5;
}
body .clear-all-button {
  border: unset;
  color: var(--background-color);
  font-size: 16px;
  font-weight: 700;
}

.filters-collapsable .ant-checkbox-wrapper span:last-child {
  text-transform: capitalize;
}
.filter-drawer .ant-drawer-content {
  border-top-right-radius: 50px;
}
@media (max-width: 767px) {
  .apply-reset-price .reset {
    margin-right: 10px;
    color: #d62215 !important;
  }
  .price-filter button {
    color: #d62215!important;
    border: 1px solid #d62215!important;
  }
  .filter-drawer .ant-slider .ant-slider-handle::after {
    box-shadow: 0 0 0 2px #d62215 !important;
  }
}
